import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Row, Col } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GameDetailEditModal from "./GameDetailEditModal";
import GameData from "../../../../../services/game.service";
import moment from "moment";
import GameDetailData from "../../../../../services/gameDetails.service";
import { enqueueSnackbar } from "notistack";
import CancelGameModal from "./DeleteGameDetailModal";
import { log } from "nvd3";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import DeletePalyerModal from "./DeleteModal";
import customAxios from "../../../../../serverCall";
import UserListData from "../../../../../services/userList.service";
import { NULL } from "sass";
import AdvertiseModal from "./AdvertiseModal";
import GameSettingsData from "../../../../../services/gameSettings.service";
import BookFullPitch from "./BookFullPitch";
import { MdModeEdit } from "react-icons/md";

const localData = JSON.parse(localStorage.getItem("userProfile"));
const Role = localData?.role;

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const GameDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [pitchNames, setPitchName] = useState([]);
  const [scroll, setScroll] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [gameDetail, setGameDetail] = useState([]);
  const [gameOtherDetail, setGameOtherDetail] = useState("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [player, setPlayer] = useState([]);
  const [isAdvertiseModalShow, setIsAdvertiseModalShow] = useState(false);
  const [isFullPitchModalShow, setIsFullPitchModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedNotes, setEditedNotes] = useState("");
  const [info, setInfo] = useState("");

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const body = {
      notes: editedNotes,
    };

    await GameSettingsData.UpdateGameSettingsDetailsOptions2(id, body);
    getGameSettingsData();
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedNotes("");
    setIsEditing(false);
  };

  const toggleCancelModal = () => {
    setShowCancelModal(!showCancelModal);
  };

  const cancelGame = () => {
    toggleCancelModal();
  };

  const GameId = state && state?.GameId ? state?.GameId : "unknown";

  const navigate = useNavigate();

  const getGameSettingsData = async () => {
    try {
      const response = await GameData.GameIDForFacility(GameId);
      setGameDetail(response.data.data[0]);
      setEditedNotes(response.data.data[0].notes);
      setGameOtherDetail(response.data?.otherInfo);
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
    }
  };
  useEffect(() => {
    getGameSettingsData();
  }, []);

  const getMainUserListData = async () => {
    try {
      const response = await GameDetailData.GetPlayerRequest();
      const result = response.data["data"];
      setPlayer(
        result.map((item) => {
          return {
            label: item.firstName + " " + item.lastName,
            value: item._id,
          };
        })
      );

      enqueueSnackbar("Get User successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
    }
  };
  useEffect(() => {
    getMainUserListData();
  }, []);

  const statusColor = (status) => {
    switch (status) {
      case "ADVERTISED":
        return "green"; // green ..
      case "AHEAD":
        return "darkblue"; //blue  ..
      case "NOT-ADVERTISED":
        return "grey"; //grey ..
      case "CANCELLED":
        return "red"; //red ..
      case "NOT-CREATED":
        return "orange"; //orange ..
      case "PRE-BOOKED":
        return "#f945e4"; //pink
      default:
        return "black";
    }
  };

  useEffect(() => {
    if (state && state.PitchName) {
      setPitchName(state.PitchName);
    }
  }, [state]);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handleViewDetailsClick = (userId) => {
    navigate(`/userlist/userview/${userId}`, { state: { SubId: userId } });
  };

  const handleConfirmCancel = async () => {
    try {
      const response = await GameDetailData.GetGameDetailSingleId(GameId);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      getGameSettingsData();
      toggleCancelModal(); // Close the modal after confirming cancel
    } catch (error) {
      console.log("GameCancel error", error);
    }
  };

  // const addPlayer = () => {
  //   if (selectedPlayer) {
  //     setGameDetail((prevGameDetail) => ({
  //       ...prevGameDetail,
  //       players: [...prevGameDetail.players, selectedPlayer],
  //     }));
  //     setSelectedPlayersList([]); // Reset selected players list
  //   }
  // };

  const handleAddPlayer = () => {
    setShowAddPlayerModal(true);
    setIsEditMode(false);
  };

  const closeAddPlayerModal = () => {
    setShowAddPlayerModal(false);
  };

  const handlePlayerSelect = (player) => {
    setSelectedPlayer(player);
  };

  // const addSelectedPlayer = () => {
  //   if (selectedPlayer) {
  //     setSelectedPlayersList([...selectedPlayersList, selectedPlayer]);
  //     setSelectedPlayer(null); // Reset selected player
  //     closeAddPlayerModal(); // Close the modal
  //   }
  // };

  const addSelectedPlayer = async () => {
    try {
      if (isEditMode) {
        const response = await GameDetailData.UpdateWallet(
          GameId,
          selectedPlayer
        );
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        getGameSettingsData();
        closeAddPlayerModal(false);
      } else {
        const response = await GameDetailData.AddPlayerRequest(
          GameId,
          selectedPlayer
        );
        setGameDetail("");
        setGameOtherDetail("");
        enqueueSnackbar(response.data.message, {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        getGameSettingsData();
      }
      setGameDetail("");
      setGameOtherDetail("");
      closeAddPlayerModal(false);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const handleConfirmDelete = async (userId) => {
    try {
      const updatedPlayers = gameDetail.players.filter(
        (player) => player.userId !== userId
      );

      setGameDetail((prevGameDetail) => ({
        ...prevGameDetail,
        players: updatedPlayers,
      }));

      enqueueSnackbar("Player deleted successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      // Close the modal
      setShowDeleteModal(false);
      setPlayerToDelete(null);
    } catch (error) {
      console.log("Delete player error", error);
      enqueueSnackbar("Failed to delete player", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const getData = async () => {
    const response = await customAxios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${0},${0}&key=AIzaSyCgMx6dGDaao_AJfu_sua3CEGbkgKfSMIs`
    );
    const address = response.data.results[0].formatted_address;
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDeleteClick = (userId) => {
    setShowDeleteModal(true);
    setPlayerToDelete(userId);
  };

  const handleConfirmPlayerDelete = async (userId) => {
    try {
      const response = await GameDetailData.DeletePlayerRequest(GameId, userId);

      setShowDeleteModal(false);
      getGameSettingsData();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleAdvertise = async () => {
    try {
      const body = {
        status: "ADVERTISED",
      };
      const response = await GameSettingsData.UpdateGameSettingsDetailsOptions2(
        id,
        body
      );
      getGameSettingsData();
      setIsAdvertiseModalShow(false);
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleFullPitchSubmit = async () => {
    try {
      const body = {
        jointype: "FULL-PITCH",
        status: "AHEAD",
        player: selectedPlayer,
      };
      await GameSettingsData.UpdateGameSettingsDetailsOptions2(id, body);
      getGameSettingsData();
      setIsFullPitchModalShow(false);
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  return (
    <>
      <div
        className="page-header"
        style={{ marginBottom: "10px", marginTop: "10px" }}
      >
        <button
          size="sm"
          className="btn btn-primary"
          style={{ borderRadius: "20px", marginBottom: "0px" }}
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div
        className="page-header2"
        style={{
          margin: "0px",
          marginBottom: "2%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0px",
        }}
      >
        <div className="d-flex gap-4 align-items-end">
          <Card
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.84)",
              border: "6px solid #00ADEF",
              textAlign: "center",
              width: "auto",
              marginLeft: "10px",
            }}
            className="p-3"
          >
            <h2
              className="main-content-title tx-24 mg-b-5 mb-2 mt-0"
              style={{ color: "white" }}
            >
              {gameDetail?.gameDate
                ? new Date(gameDetail?.gameDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
                : "unknown"}
            </h2>
            <h5 className="mb-0" style={{ color: "white" }}>
              {moment(gameDetail.startTime).format("HH:mm") +
                " - " +
                moment(gameDetail.endTime).format("HH:mm")}
            </h5>
          </Card>
          <div className="pb-2">
            <Button
              style={{
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "10px",
                marginRight: "10px",
                width: "auto",
                height: "fit-content",
              }}
              className="bg-success"
            >
              {moment(gameDetail?.gameDate).format('dddd')}
            </Button>
          </div>
        </div>
        <div
          className="d-flex justify-content-between "
          style={{
            marginRight: "10px",
            display: "flex",
            margin: "10px",
            alignItems: "end",
          }}
        >
          {gameDetail?.status !== "AHEAD" && (
            <Button
              onClick={() => {
                setIsFullPitchModalShow(true);
                setInfo("RepeatedBooking");
              }}
              style={{
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "10px",
                marginRight: "10px",
                width: "auto",
                height: "fit-content",
              }}
            >
              Repeated Booking
            </Button>
          )}
          {(gameDetail?.status === "CANCELLED" ||
            gameDetail?.status === "NOT-ADVERTISED") && (
              <Button
                onClick={() => setIsAdvertiseModalShow(true)}
                style={{
                  fontWeight: "bold",
                  padding: "10px",
                  borderRadius: "10px",
                  marginRight: "10px",
                  width: "auto",
                  height: "fit-content",
                }}
                className="bg-success"
              >
                Adverstise
              </Button>
            )}

          {gameDetail?.status !== "AHEAD" && (
            <Button
              onClick={() => {
                setIsFullPitchModalShow(true);
                setInfo("BookFullPitch");
              }}
              style={{
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "10px",
                marginRight: "10px",
                width: "auto",
                height: "fit-content",
              }}
            >
              Book Full-Pitch
            </Button>
          )}
          {Role === "MASTER-ADMIN" ? (
            <Button
              onClick={() => setShowEditModal(true)}
              style={{
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "10px",
                marginRight: "10px",
                width: "auto",
                height: "fit-content",
              }}
            >
              <i style={{ marginRight: "5px" }} className="fa fa-pencil"></i>
              Edit
            </Button>
          ) : null}

          {/* {Role === "MASTER-ADMIN" ? ( */}
          <Button
            onClick={toggleCancelModal}
            type="button"
            variant="danger"
            style={{
              // backgroundColor: "#000000",
              fontWeight: "bold",
              padding: "10px",
              borderRadius: "10px",
              width: "auto",
              height: "fit-content",
            }}
          >
            <i className="fa fa-times m-1" aria-hidden="true"></i>Cancel Game
          </Button>
          {/* ) : null} */}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="col-lg-6 col-sm-12 mb-3" style={{}}>
              <Card
                style={{
                  overflow: "hidden",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="col-lg-12 mb-3">
                  <Card.Header style={{ display: "flex" }}>
                    <h3>Details </h3>
                  </Card.Header>
                  <div className="d-flex">
                    <div className="col-lg-6 mb-3">
                      <Card.Body style={{ overflow: "auto" }}>
                        <h5 style={{ fontWeight: "bolder" }}>Host Name</h5>
                        <p>
                          {gameDetail?.hostName ? gameDetail?.hostName : "-"}
                        </p>

                        <h5 style={{ fontWeight: "bolder" }}>Pitch Type</h5>
                        <p>{gameDetail?.pitchtype}</p>

                        <h5 style={{ fontWeight: "bolder" }}>Pitch Name</h5>
                        <p>{gameDetail?.pitch}</p>
                        <h5 style={{ fontWeight: "bolder" }}>Initiated By</h5>
                        <p>
                          {gameOtherDetail?.intiatedby ? (
                            `${gameOtherDetail?.intiatedby}`
                          ) : (
                            <span>-</span>
                          )}
                        </p>
                        <h5 style={{ fontWeight: "bolder" }}>Payment Status</h5>
                        <p
                          style={{
                            color:
                              gameOtherDetail?.paymentstatus === "Fully paid"
                                ? "green"
                                : gameOtherDetail?.paymentstatus === "Not paid"
                                  ? "red"
                                  : "orange",
                          }}
                        >
                          {gameOtherDetail?.paymentstatus}
                        </p>
                        {/* <h5 style={{ fontWeight: "bolder" }}>Payment Option</h5>
                        <p>{gameOtherDetail?.paymentoptions}</p> */}
                        {gameOtherDetail?.intiatedby &&
                          gameOtherDetail?.isDeposit && (
                            <>
                              <h5 style={{ fontWeight: "bolder" }}>
                                Full price
                              </h5>
                              <p>{gameOtherDetail?.Mainprice}</p>
                              <h5 style={{ fontWeight: "bolder" }}>
                                Paid amount
                              </h5>
                              <p>
                                {" "}
                                {gameOtherDetail?.fullPaymentType === "FULL"
                                  ? gameOtherDetail?.Mainprice
                                  : gameOtherDetail?.depositPrice}
                              </p>
                              {gameOtherDetail?.fullPaymentType !== "FULL" && (
                                <>
                                  <h5 style={{ fontWeight: "bolder" }}>
                                    Remaining amount
                                  </h5>
                                  <p>
                                    {gameOtherDetail?.Mainprice -
                                      gameOtherDetail?.depositPrice}
                                  </p>
                                </>
                              )}
                            </>
                          )}

                        {gameDetail?.isAllowOpenInvitation && (
                          <>
                            <h5 style={{ fontWeight: "bolder" }}>
                              Open Slots Available
                            </h5>
                            <p>
                              {gameDetail?.openInvitationCount} / {gameDetail?.totalOpenInvitationCount}
                            </p>
                          </>
                        )}
                      </Card.Body>
                    </div>
                    <hr
                      style={{
                        width: "1px",
                        height: "250px",
                        borderLeft: "3px solid black",
                        margin: "17px 1px",
                      }}
                    />
                    <div className="col-lg-6 mb-3">
                      <Card.Body>
                        <h5 style={{ fontWeight: "bolder" }}>Location</h5>
                        <p>{gameDetail?.facilityaddress}</p>

                        <h5 style={{ fontWeight: "bolder" }}>Status</h5>
                        <p
                          style={{
                            backgroundColor: statusColor(gameDetail?.status),
                            color: "white",
                            textAlign: "center",
                            padding: "4px",
                            fontSize: "12px",
                            borderRadius: "5px",
                            width: "100%",
                          }}
                        >
                          {gameDetail?.status}
                        </p>

                        <h5 style={{ fontWeight: "bolder" }}>
                          Game Setting Name
                        </h5>
                        <p>{gameDetail?.gamesettingName}</p>
                        <div>
                          <h5 style={{ fontWeight: "bolder" }}>Notes</h5>
                          {!isEditing ? (
                            <>
                              {gameDetail?.notes ? (
                                <Row>
                                  <Col>
                                    <p>{gameDetail?.notes}</p>
                                  </Col>
                                  <Col xs="auto">
                                    <MdModeEdit
                                      onClick={handleEditClick}
                                      style={{
                                        cursor: "pointer",
                                        width: "18px",
                                        height: "18px",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              ) : (
                                <div className="d-flex justify-content-between align-items-center">
                                  <span>-</span>
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => setIsEditing(true)}
                                  >
                                    + Add note
                                  </Button>
                                </div>
                              )}
                            </>
                          ) : (
                            <Form>
                              <Form.Group controlId="formNotes">
                                <Form.Control
                                  as="textarea"
                                  name="notes"
                                  rows={4}
                                  value={editedNotes}
                                  onChange={(e) =>
                                    setEditedNotes(e.target.value)
                                  }
                                />
                              </Form.Group>
                              <Row className="mt-2">
                                <Col xs="auto">
                                  <Button
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={handleCancelClick}
                                    className="font-weight-bold text-sm"
                                    type="button"
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                                <Col xs="auto">
                                  <Button
                                    size="sm"
                                    variant="primary"
                                    type="button"
                                    onClick={handleSaveClick}
                                    className="font-weight-bold text-sm"
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </div>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div
              className="col-lg-6 col-sm-12 mb-3"
              style={{ display: "flex" }}
            >
              <Card
                style={{
                  overflow: "hidden",
                  width: "100%",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                }}
              >
                <Card.Header>
                  <h3>Location </h3>{" "}
                </Card.Header>
                <Card.Body>
                  {gameDetail != "" && (
                    <iframe
                      src={`https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14877.966210690114!2d${gameDetail?.facilityLocation[0]}!3d${gameDetail?.facilityLocation[1]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707804531760!5m2!1sen!2sin`}
                      style={{ border: "0", width: "100%", height: "100%" }}
                      allowFullScreen={true}
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    />
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 mb-3 mt-3">
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  className="col-lg-6 col-sm-12 mb-3"
                  style={{ display: "flex" }}
                >
                  <Card
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <Card.Header>
                      <h3>Rules Of The Game </h3>
                    </Card.Header>
                    <Card.Body>
                      {gameDetail?.rules &&
                        gameDetail?.rules.map((rule, index) => (
                          <div key={index}>
                            <p>
                              <strong>{`Rule ${index + 1}:`}</strong>{" "}
                              <p style={{ fontSize: "16px" }}>- {rule}</p>
                            </p>
                          </div>
                        ))}
                    </Card.Body>
                    <Card.Header>
                      <h3>Importants Game </h3>
                    </Card.Header>
                    <Card.Body>
                      {gameDetail?.important &&
                        gameDetail?.important.map((imp, index) => (
                          <div key={index}>
                            <p>
                              <strong>{`Important ${index + 1}:`}</strong>{" "}
                              <p style={{ fontSize: "16px" }}>- {imp}</p>
                            </p>
                          </div>
                        ))}
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-lg-6 col-sm-12 mb-3">
                  <Card
                    style={{
                      overflow: "hidden",
                      width: "100%",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    <Card.Header
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>List Of Players</h3>
                      {Role === "MASTER-ADMIN" ? (
                        <Button
                          size="sm"
                          onClick={handleAddPlayer}
                          style={{
                            borderRadius: "10px",
                            padding: "7px",
                            fontWeight: "bold",
                          }}
                          title="Add Player"
                        >
                          <i
                            style={{ marginRight: "5px" }}
                            className="fa fa-plus"
                          ></i>{" "}
                          Add Player
                        </Button>
                      ) : null}
                    </Card.Header>
                    <Card.Body>
                      <table className="table table-bordered">
                        <thead style={{ textAlign: "center" }}>
                          <tr>
                            <th>Name Of Players</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: "center" }}>
                          {gameDetail?.players &&
                            gameDetail?.players.length > 0 ? (
                            gameDetail?.players.map((data, index) => (
                              <tr key={index}>
                                <td>
                                  <p>
                                    <a href="#!" className="text-dark">
                                      {data.firstName} {data.lastName}
                                    </a>
                                  </p>
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {Role === "MASTER-ADMIN" ? (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <Button
                                          size="sm"
                                          variant="primary"
                                          onClick={() =>
                                            handleViewDetailsClick(data.userId)
                                          }
                                          title="View Details"
                                        >
                                          <i className="fa fa-eye"></i>
                                        </Button>

                                        {/**************************** Add Player DELETE ***************************************/}

                                        <Button
                                          size="sm"
                                          variant="danger"
                                          // onClick={() => {
                                          //   setPlayerToDelete(player.userId);
                                          //   setShowDeleteModal(true);
                                          // }}
                                          onClick={() =>
                                            handleDeleteClick(data.userId)
                                          }
                                          title="Delete Player"
                                        >
                                          <i className="fa fa-trash"></i>
                                        </Button>
                                      </div>
                                    </>
                                  ) : (
                                    <Button
                                      size="sm"
                                      variant="primary"
                                      disabled
                                      title="Not Usable"
                                    >
                                      <i
                                        class="fa fa-eye-slash"
                                        aria-hidden="true"
                                      ></i>
                                    </Button>
                                  )}
                                </td>
                                {/**************************** Add Player DELETE ***************************************/}
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="2">No data available</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showEditModal && (
        <GameDetailEditModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          isEdit={true}
          gameDetail={gameDetail}
          gameId={id}
          getGameSettingsData={getGameSettingsData}
        />
      )}

      {showCancelModal && (
        <CancelGameModal
          show={showCancelModal}
          onHide={toggleCancelModal}
          handleConfirmCancel={handleConfirmCancel}
        />
      )}

      {showDeleteModal && (
        <DeletePalyerModal
          showDeleteModal={showDeleteModal}
          confirmDelete={handleConfirmDelete}
          handleClose={() => {
            setShowDeleteModal(false);
            setPlayerToDelete(null);
          }}
          userId={playerToDelete}
          handleConfirmPlayerDelete={handleConfirmPlayerDelete}
        />
      )}

      {isAdvertiseModalShow && (
        <AdvertiseModal
          show={isAdvertiseModalShow}
          handleClose={() => {
            setIsAdvertiseModalShow(false);
          }}
          handleAdvertise={handleAdvertise}
        />
      )}

      {isFullPitchModalShow && (
        <BookFullPitch
          show={isFullPitchModalShow}
          handleClose={() => {
            setIsFullPitchModalShow(false);
            setSelectedPlayer("");
          }}
          handleFullPitchSubmit={handleFullPitchSubmit}
          player={player}
          handlePlayerSelect={handlePlayerSelect}
          gameDetail={gameDetail}
          selectedPlayer={selectedPlayer}
          setSelectedPlayer={setSelectedPlayer}
          getGameSettingsData={getGameSettingsData}
          setIsFullPitchModalShow={setIsFullPitchModalShow}
          info={info}
        />
      )}

      {/* Add Player Modal */}
      <Modal
        centered
        backdrop="static"
        show={showAddPlayerModal}
        onHide={closeAddPlayerModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Player</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="selectPlayer">
            <Form.Label>Select Player</Form.Label>

            <Form.Control
              as="select"
              onChange={(e) => handlePlayerSelect(e.target.value)} // Update this line
            >
              <option value="">Select Player</option>
              {player &&
                player.map((item, index) => (
                  <option key={index} value={item.value}>
                    {" "}
                    {/* Set value attribute */}
                    {item.label}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeAddPlayerModal}
            style={{ borderRadius: "10px" }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={addSelectedPlayer}
            style={{ borderRadius: "10px" }}
          >
            Add Player
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GameDetail;
